.slider {
  @apply w-full h-2 overflow-hidden;
  &-thumb {
    @apply h-full w-4 bg-primary-light top-0;
    @apply outline-none border-none ;
  }
  &-track {
    @apply bg-primary w-full h-full top-0 left-0;
    &-1 {
      @apply bg-primary-dark;
    }
  }
}