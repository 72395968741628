@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";
@import "button";
@import "slider";
@import "assignments-table";

:root {
  cursor: url("../assets/cursors/pointer/4.png"), auto;
  
  --rt-transition-show-delay: 0.3s;
  --rt-transition-closing-delay: 0.3s;
}

:active {
  cursor: url("../assets/cursors/pointer/6.png"), auto;
}

.dragging {
  cursor: url("../assets/cursors/drag/1.png"), auto;
}

.cursor-pointer {
  cursor: url("../assets/cursors/pointer/1.png"), auto;

  &:active {
    cursor: url("../assets/cursors/pointer/4.png"), auto;
  }
}

html,
body,
#root {
  @apply w-full h-full;
}

body {
  font-family: "MedievalSharp";
  @apply text-gray-100;
  @apply scrollbar-thumb-primary;
}

h3 {
  @apply text-xl
}

h4 {
  @apply text-lg
}

p {
  @apply text-sm;
  @apply mb-2;
}

a {
  @apply text-primary;
  @apply underline;
}

ul {
  @apply pl-2;
  @apply text-sm;
}

table.table {
  tr {
    th, td {
      @apply pr-4 last:pr-0;
    }
  }
}

.bg-ornamental-top {
  @apply bg-[url('../assets/ui/ui-border-top.png')] bg-repeat-x bg-top;
  @apply pt-4;
}

.bg-ornamental-bottom {
  @apply bg-[url('../assets/ui/ui-border-bottom.png')] bg-repeat-x bg-bottom;
  @apply pb-4;
}

.bg-ornamental {
  @apply bg-[url('../assets/ui/ui-border-top.png'),_url('../assets/ui/ui-border-bottom.png')] bg-repeat-x bg-[position:top,_bottom];
  @apply py-4;
}

.surface-before {
  @apply relative;
  @apply before:content-[''];
  @apply before:w-full before:h-full before:bg-primary before:rounded-sm before:absolute before:left-2 before:top-2;
  & > * {
    @apply relative;
  }
}

.border-fancy {
  @apply bg-[url('../assets/ui/button/button-top-left.svg'),_url('../assets/ui/button/button-top.svg'),_url('../assets/ui/button/button-top-right.svg'),_url('../assets/ui/button/button-left.svg'),_url('../assets/ui/button/button-right.svg'),_url('../assets/ui/button/button-bottom-left.svg'),_url('../assets/ui/button/button-bottom.svg'),_url('../assets/ui/button/button-bottom-right.svg')];
  @apply bg-[position:left_top,_top,_right_top,_left,_right,_left_bottom,_bottom,_bottom_right];
  background-repeat: no-repeat, repeat-x, no-repeat, repeat-y, repeat-y, no-repeat, repeat-x, no-repeat;
  @apply p-4;
  //@apply shadow-[inset_0_0_16px_rgba(0,0,0,0.5)];
}

#root .react-tooltip {
  @apply bg-surface bg-opacity-100;
  @apply border border-primary;
  @apply opacity-100;
  @apply p-2;
  @apply rounded-sm;
  @apply text-sm;
}

.surface-box {
  @apply bg-black bg-opacity-50;
  @apply border border-primary rounded-sm;
  @apply p-2;
}