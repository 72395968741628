.button {

  @apply inline-flex gap-8 items-center justify-center;
  @apply relative cursor-pointer transition-colors duration-300;
  @apply px-4 py-2;
  @apply text-gray-100 hover:text-gray-300;
  @apply hover:drop-shadow-[0_0_3px_rgba(255,255,255,0.2)] transition-all duration-500;


  &-normal,
  &-disabled {
    @apply bg-[url('../assets/ui/button/button-top-left.svg'),_url('../assets/ui/button/button-top.svg'),_url('../assets/ui/button/button-top-right.svg'),_url('../assets/ui/button/button-left.svg'),_url('../assets/ui/button/button-right.svg'),_url('../assets/ui/button/button-bottom-left.svg'),_url('../assets/ui/button/button-bottom.svg'),_url('../assets/ui/button/button-bottom-right.svg')];
    @apply bg-[position:left_top,_top,_right_top,_left,_right,_left_bottom,_bottom,_bottom_right];
    background-repeat: no-repeat, repeat-x, no-repeat, repeat-y, repeat-y, no-repeat, repeat-x, no-repeat;
    @apply px-8 py-2;
    @apply active:scale-95 transform active:duration-300;
  }

  &-icon {
    @apply text-gray-100 hover:text-primary-dark;
    @apply bg-primary;
    @apply hover:border-primary-dark;
    @apply p-1;
  }

  &-transparent {
    @apply bg-none border-none;
    @apply hover:drop-shadow;
  }

  &-disabled {
    @apply bg-gray-600;
    @apply active:scale-100;
  }
}