table.assignments-table {
  thead {
    th {
      @apply pb-1;
      @apply border-b border-primary;
    }
  }

  tbody {
    tr {
      td {}
    }
  }

  tr {
    @apply hover:bg-white hover:bg-opacity-5 transition-colors duration-300;
    th,
    td {
      @apply py-1 px-1;
      @apply text-center first:text-left
    }
  }
}